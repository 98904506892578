.App {
  text-align: center;
}

.Typer {
  font-size: 1.2rem;
}

.footer {
  background: #282c34;
  color: white;
  padding: 1rem 0;
}

.Black-Section {
  width: 100%;
  margin-top: 5vh;
  background: #282c34;
  color: #fff;
}

.White-Section {
  width: 100%;
  margin-top: 5vh;
  background-color: #fff;
  color: #282c34;
}

.Scrollspy-section {
  width: 100%;
  min-height: 100vh;
  background: #fff;
}

.Scrollspy-navigation {
  position: sticky;
  top: 0;
  background: #282c34;
  padding: 1rem 0;
}

.Scrollspy-navigation-item {
  padding: 0 1rem;
  color: #fff;
  font-size: 1.2rem;
  text-decoration-style: none;
}

.Scrollspy-navigation-item--active {
  font-weight: bold;
  font-size: 1.2;
  text-decoration: overline solid orange;
}

.Scrollspy-navigation-item--hover {
  font-weight: bold;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  font-weight: bold;
  color: orange;
}